import React from 'react';
import PropTypes from 'prop-types';
import DropdownSelect from 'components/FormComponents/dropdown/DropdownSelect';
import RegionalSettingOption from 'components/authentication/RegionalSettingOption';

const PreferredLocale = ({
  languages, locales, brandLocale, locale, preferredLanguage, preferredLang, onChangeLocale, translations
}) => {
  const brandLanguage = locales?.[brandLocale]?.language ?? '';
  const {languageOptions, switchTo} = translations;

  return (
    <div className="form-group">
      <div className="mb-2 d-flex align-items-center">
        <i className="icon icon-world-2 mr-2"/>
        <b>{languageOptions}</b>
        {
          locale !== 'en_IE' && <>
            <span className="text-muted">&nbsp;•&nbsp;</span>
            {switchTo}
            <span className="ml-1 text-primary cursor-pointer" onClick={() => onChangeLocale(brandLocale)}>
              {brandLanguage}
            </span>
          </>
        }
      </div>
      <DropdownSelect
        value={preferredLanguage}
        name="preferredLocale"
        containerProps={{floatingLabel: false}}
      >
        {(handleDropdown) =>
          languages.filter(({language}) => language !== 'ru').map(({locale, language, label}) => (
            <RegionalSettingOption
              key={locale}
              item={language}
              label={label}
              preferredItem={preferredLang}
              onClick={() => {
                handleDropdown();
                onChangeLocale(locale);
              }}
            />
          ))
        }
      </DropdownSelect>
    </div>
  );
};

PreferredLocale.propTypes = {
  languages: PropTypes.array.isRequired,
  locales: PropTypes.object.isRequired,
  brandLocale: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  preferredLanguage: PropTypes.string.isRequired,
  preferredLang: PropTypes.string.isRequired,
  onChangeLocale: PropTypes.func.isRequired,
  translations: PropTypes.shape({
    languageOptions: PropTypes.string.isRequired,
    switchTo: PropTypes.string.isRequired
  })
};

export default PreferredLocale;
