import {useEffect} from 'react';
import {debounce} from 'lodash';

const useWindowEvent = (event, handler, duration = 100) => {
  useEffect(() => {
    window.addEventListener(event, debounce(handler, duration));

    return () => window.removeEventListener(event, handler);
  }, [event, handler, duration]);
};

export default useWindowEvent;
