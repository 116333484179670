import React, {memo} from 'react';
import PropTypes from 'prop-types';

const LoggedInEmployee = ({employee}) => (
  <div className="bg-secondary text-center py-1">Logged in as {employee.employeeName} ({employee.employeeID})</div>
);

LoggedInEmployee.propTypes = {
  employee: PropTypes.object.isRequired
};

export default memo(LoggedInEmployee);
