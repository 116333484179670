import React, {Fragment} from 'react';
import {observer} from 'mobx-react';
import {toJS} from 'mobx';
import PropTypes from 'prop-types';
import {PropTypes as MobxPropTypes} from 'mobx-react';
import * as R from 'ramda/src/index';
import classNames from 'classnames';
import {FieldContainer, TextInput, Label, FieldErrors} from './components';

const properties = (props, classes) => ({
  ...props,
  className: classNames(`${props.className || ''} ${classes}`)
});

const ExpirationDate = ({
  floatingLabel, fields, onChange, onBlur, disabled, required,
  containerProps, inputProps, labelProps, errorsContainerProps
}) => {
  const errors = R.pipe(
    R.pluck('error'),
    R.flatten,
    R.uniq
  )(toJS(fields));
  const hasError = Boolean(errors.length);
  const fieldsLength = fields.length;
  const expDateClass = classNames('d-flex flex-column mb-3', {
    'has-error': hasError,
    'disabled': disabled
  });

  return (
    <div className={expDateClass}>
      <div className="d-flex expiration-date">
        {
          fields.map((field, index) => {
            const {value, name, placeholder, label} = field;
            return <Fragment key={index}>
              <FieldContainer
                {...properties(containerProps, 'm-0 w-1-1')}
                value={value}
                floatingLabel={floatingLabel}
              >
                <TextInput
                  {...properties(inputProps, 'border-0')}
                  name={name}
                  value={value}
                  placeholder={`${placeholder || label}${required ? '*' : ''}`}
                  disabled={disabled}
                  onChange={(e) => onChange(field, e.target.value, name)}
                  onBlur={(e) => onBlur(field, e.target.value, name)}
                />
                <Label {...labelProps} htmlFor={name}>{label}{`${required ? '*' : ''}`}</Label>
              </FieldContainer>
              {fieldsLength !== (index + 1) &&
                <span className="align-self-end font-light px-2 display-3 pb-1">/</span>}
            </Fragment>;
          })
        }
      </div>
      {hasError && <FieldErrors {...errorsContainerProps} errors={errors} />}
    </div>
  );
};

ExpirationDate.propTypes = {
  floatingLabel: PropTypes.bool,
  fields: PropTypes.oneOfType([
    MobxPropTypes.observableArrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        label: PropTypes.string.isRequired,
        error: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.string).isRequired
      })
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        error: PropTypes.array.isRequired
      }).isRequired
    ).isRequired]
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  containerProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  labelProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  errorsContainerProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  })
};

ExpirationDate.defaultProps = {
  floatingLabel: true,
  errors: [],
  disabled: false,
  required: false,
  // Not required properties
  placeholder: null,
  containerProps: {},
  inputProps: {},
  labelProps: {},
  errorsContainerProps: {},
  onBlur: () => {}
};

export default observer(ExpirationDate);
