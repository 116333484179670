const isElementInViewport = (el, fromBottom = 0) => {
  if (!el) {
    return;
  }

  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    (rect.bottom + fromBottom) <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export {isElementInViewport};
