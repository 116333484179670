import React from 'react';
import PropTypes from 'prop-types';

const Avatar = ({circle, children}) => (
  <div
    className="mr-2 bg-primary rounded-circle d-flex justify-content-center align-items-center"
    style={{width: `${circle}px`, height: `${circle}px`}}
  >
    {children}
  </div>
);


Avatar.defaultProps = {
  circle: 24
};

Avatar.propTypes = {
  circle: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired
};

export default Avatar;
