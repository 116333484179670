import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {PropTypes as MobxPropTypes} from 'mobx-react';
import {SelectPlus, Label, FieldErrors} from './components';

const SelectPlusField = ({
  selectName, value, label, onChange, options, htmlFor, errors, placeholder,
  selectProps, labelProps, errorsContainerProps
}) => {
  const hasError = !!errors.length;

  return (
    <div className="form-group">
      <SelectPlus
        {...selectProps}
        id={htmlFor}
        placeholder={placeholder}
        name={selectName}
        value={value}
        hasError={hasError}
        onChange={onChange}
        options={options}
      />
      <Label {...labelProps} htmlFor={htmlFor}>{label}</Label>
      <FieldErrors {...errorsContainerProps} errors={errors} />
    </div>
  );
};

SelectPlusField.propTypes = {
  placeholder: PropTypes.string.isRequired,
  selectName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  errors: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.string).isRequired,
  htmlFor: PropTypes.string,
  selectProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  labelProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  errorsContainerProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  })
};

SelectPlusField.defaultProps = {
  floatingLabel: true,
  inputName: '',
  value: '',
  label: '',
  errors: [],
  // Not required properties
  htmlFor: null,
  placeholder: null,
  containerProps: {},
  inputProps: {},
  labelProps: {},
  errorsContainerProps: {}
};

export default memo(SelectPlusField);
