const modalCustomStyles = {
  overlay: {
    zIndex: '1050'
  },
  content: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    padding: '0',
    borderRadius: '0',
    borderColor: '#fff',
    transform: 'translate(0%, 0%)'
  }
};

export {modalCustomStyles};
