import request from 'superagent';

const registerUser = (payload) =>
  request
    .post('/api/users')
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .send(payload)
    .ok(({status}) => [201, 400].includes(status))
    .then(({body, status}) => ({body, status}));

const loginUser = (payload) =>
  request
    .post('/api/users/login')
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .send(payload)
    .ok(({status}) => [200, 201, 400, 404].includes(status))
    .then(({body, status}) => ({body, status}));

const logoutUser = () =>
  request
    .delete('/api/users/login')
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .ok(({status}) => [204].includes(status))
    .then(({body, status}) => ({body, status}));

export {registerUser, loginUser, logoutUser};
