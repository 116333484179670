import request from 'superagent';
import {getAuthParams} from './utils';

const getUserAccount = () => {
  const {uuid} = getAuthParams();

  return request
    .get(`/api/users/${uuid}?basicData=true`)
    .set('Content-Type', 'application/json')
    .set('Accept', 'application/json')
    .ok(({status}) => [200, 401].includes(status))
    .then(({body, status}) => ({body, status}));
};

export {getUserAccount};
