import React, {memo} from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda/src/index';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import classNames from 'classnames';
import {isAirtickets} from 'helpers/stableValues';

const renderItemLink = ({name, url, target}) => (
  <li key={name}>
    <a href={url} title={name} target={target} className="dropdown-item">{name}</a>
  </li>
);

const renderMainLink = (link) => {
  const main = link.main;
  const values = !R.isEmpty(link.values) && link.values;

  if (main && values) {
    return (
      <Dropdown
        componentClass="li"
        className="nav-item"
        id={link.main.name}
        key={link.main.name}
      >
        <Dropdown.Toggle
          useAnchor
          className="navbar-link d-flex align-items-center"
        >
          {link.main.name}
          <span className="icon icon-triangle-sm-down ml-1" aria-hidden="true"></span>
        </Dropdown.Toggle>
        <Dropdown.Menu >
          {
            values.map(renderItemLink)
          }
        </Dropdown.Menu>
      </Dropdown>
    );
  } else if (main) {
    const navbarLinkClass = classNames({
      'navbar-link': true,
      [main.icon]: isAirtickets
    });
    const {name, url, target, rel} = main;
    return (
      <li className="nav-item" key={name}>
        <a className={navbarLinkClass} title={name} href={url} target={target} rel={rel}>
          {name}
        </a>
      </li>
    );
  }
};

const Navigation = ({links, userAccountID}) => {
  const ulClasses = classNames('navbar-nav mr-auto', {
    'navbar-nav-main': !userAccountID,
    'airtickets': isAirtickets
  });

  return (
    <ul className={ulClasses}>
      {
        links.map(renderMainLink)
      }
    </ul>
  );
};

Navigation.propTypes = {
  links: PropTypes.array.isRequired,
  userAccountID: PropTypes.string
};

export default memo(Navigation);
