import React from 'react';
import PropTypes from 'prop-types';
import {observer, PropTypes as MobxPropTypes} from 'mobx-react';
import {InputField} from 'components/FormComponents';

const InputElement = ({name, label, field, info, className, image,
  icon, disabled, required, inputProps, onChangeField, onBlurField}) => (
  <div className={className}>
    <InputField
      inputName={name}
      htmlFor={name}
      label={label}
      value={field.value}
      errors={field.error}
      image={image}
      icon={icon}
      disabled={disabled}
      required={required}
      inputProps={inputProps}
      onChange={(e) => onChangeField(field, e.target.value, name)}
      onBlur={(e) => onBlurField(field, e.target.value, name)}
    />
    {info}
  </div>
);

InputElement.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  field: PropTypes.shape({
    value: PropTypes.string.isRequired,
    error: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.string).isRequired
  }).isRequired,
  info: PropTypes.string.isRequired,
  className: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]),
    width: PropTypes.number,
    height: PropTypes.number
  }),
  icon: PropTypes.shape({
    className: PropTypes.string,
    onClick: PropTypes.func
  }),
  disabled: PropTypes.bool.isRequired,
  required: PropTypes.bool.isRequired,
  inputProps: PropTypes.object,
  onChangeField: PropTypes.func.isRequired,
  onBlurField: PropTypes.func
};

InputElement.defaultProps = {
  disabled: false,
  required: false,
  info: '',
  onChangeField: () => undefined,
  onBlurField: () => undefined
};

export default observer(InputElement);
