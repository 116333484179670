import validate from 'validate.js';
import {fullname, email, password} from 'User/helpers/validator';

const loginConstraints = {
  email: {...email},
  password: {...password}
};

const validateLogin = (email, password) =>
  new Promise((resolve) => {
    const error = validate({
      email: email.value,
      password: password.value
    }, loginConstraints);

    resolve(error);
  });

const registerConstraints = {
  firstName: {...fullname},
  lastName: {...fullname},
  email: {...email}
};

const validateRegister = (firstName, lastName, email) =>
  new Promise((resolve) => {
    const error = validate({
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value
    }, registerConstraints);

    resolve(error);
  });

export {validateLogin, validateRegister};
