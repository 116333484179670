import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const RegionalSettingOption = ({item, label, preferredItem, onClick}) => {
  const listGroupClasses = classnames(
    'select-list-group list-group-flush px-2 d-block text-dark',
    {
      'bg-select-item': item === preferredItem
    }
  );

  return (
    <div className={listGroupClasses} onClick={onClick}>
      {label}
    </div>
  );
};

RegionalSettingOption.propTypes = {
  item: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  preferredItem: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default RegionalSettingOption;
