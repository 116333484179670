import {useCallback, useReducer} from 'react';
import {showNotification} from 'helpers/Notifications';
import {baseRoute} from 'User/helpers/routes';
import {userErrorMessagesTranslations} from 'User/helpers/translations';

const actionTypes = {
  pending: 'pending',
  resolved: 'resolved',
  rejected: 'rejected'
};

const initialState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  data: null,
  error: null
};

const stateReducer = (state, action) => {
  switch (action.type) {
  case actionTypes.pending:
    return {isLoading: true, isSuccess: false, isError: false, data: null, error: null};
  case actionTypes.resolved:
    return {isLoading: false, isSuccess: true, isError: false, data: action.data, error: null};
  case actionTypes.rejected:
    return {isLoading: false, isSuccess: false, isError: true, data: null, error: action.error};
  default:
    throw new Error(`Unhandled action type: ${action.type}`);
  }
};

const useAsync = () => {
  const [state, dispatch] = useReducer(stateReducer, initialState);

  const execute = useCallback(async (asyncFn) => {
    try {
      dispatch({type: actionTypes.pending});
      const {body, status} = await asyncFn;
      const isSuccess = [200, 201, 204].includes(status);

      if (isSuccess) {
        dispatch({type: actionTypes.resolved, data: body?.response});
      } else {
        if (body?.response?.error?.message) {
          showNotification(body?.response?.error?.message, 'warning');
        }
        dispatch({type: actionTypes.rejected, error: body.response});
      }

      if (status === 401 && localStorage.getItem('user')) {
        localStorage.removeItem('user');
        window.location.href = baseRoute;
        return {};
      }

      return {
        isSuccess,
        isError: !isSuccess,
        [isSuccess ? 'data' : 'error']: body?.response,
        [isSuccess ? 'error' : 'data']: null
      };

    } catch {
      showNotification(userErrorMessagesTranslations.errorTryAgain, 'warning');
      dispatch({type: actionTypes.rejected, error: null});
      throw {isSuccess: false, isError: true, data: null, error: null};
    }
  }, [dispatch]);

  return {...state, execute};

};

export default useAsync;
