import React from 'react';
import PropTypes from 'prop-types';
import DropdownSelect from 'components/FormComponents/dropdown/DropdownSelect';
import RegionalSettingOption from 'components/authentication/RegionalSettingOption';
import currencySymbol from 'lib/helpers/currency-symbol-map';

const currencyWithSymbol = (currency) => `${currency} - ${currencySymbol[currency]}`;

const PreferredCurrency = ({currencies, currency, preferredCurrency, onChangeCurrency, translations}) => {
  return (
    <div className="form-group">
      <div className="mb-2 d-flex align-items-center">
        <i className="icon icon-coins mr-2"/>
        <b>{translations.currencyOptions}</b>
      </div>
      <DropdownSelect
        value={currencyWithSymbol(currency)}
        name="preferredCurrency"
        containerProps={{floatingLabel: false}}
      >
        {(handleDropdown) =>
          currencies.map((currency) => (
            <RegionalSettingOption
              key={currency}
              item={currency}
              label={currencyWithSymbol(currency)}
              preferredItem={preferredCurrency}
              onClick={() => {
                handleDropdown();
                onChangeCurrency(currency);
              }}
            />
          ))
        }
      </DropdownSelect>
    </div>
  );
};

PreferredCurrency.propTypes = {
  currencies: PropTypes.array.isRequired,
  currency: PropTypes.string.isRequired,
  preferredCurrency: PropTypes.string.isRequired,
  onChangeCurrency: PropTypes.func.isRequired,
  translations: PropTypes.shape({
    currencyOptions: PropTypes.string.isRequired
  })
};

export default PreferredCurrency;
