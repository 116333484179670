import React from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import {cleanHtml} from 'helpers/commonHelper';

const PopOverWithTrigger = ({showPopover = true, popOverText, popoverId, placement, triggerNode,
  injection = false, style = {}}) => {

  if (!showPopover) {
    return triggerNode;
  }

  const popover = injection ?
    <Popover style={style} id={popoverId}>
      <span dangerouslySetInnerHTML={{__html: cleanHtml(popOverText)}}></span>
    </Popover> :
    <Popover style={style} id={popoverId}>{popOverText}</Popover>;

  return (
    <OverlayTrigger trigger={['hover', 'focus']} placement={placement} overlay={popover} defaultShow>
      {triggerNode}
    </OverlayTrigger>
  );
};

PopOverWithTrigger.propTypes = {
  showPopover: PropTypes.bool.isRequired,
  popOverText: PropTypes.string.isRequired,
  popoverId: PropTypes.string.isRequired,
  placement: PropTypes.string.isRequired,
  triggerNode: PropTypes.node.isRequired,
  injection: PropTypes.bool.isRequired,
  style: PropTypes.object.isRequired
};


export default PopOverWithTrigger;
