import postal from 'postal';
import * as R from 'ramda/src/index';
import NavbarStore from './store';
import {
  brand, website, menuHeader, locales, localeFull, brandLocale, multicurrency,
  isoCurrency, cms, contactForm, isAffiliate
} from 'helpers/stableValues';
import {getHost} from 'helpers/commonHelper';
import {isEsky} from 'ferries/helpers/common';
import {getAuthParams} from 'providers/user/utils';
import {getUserAccount} from 'providers/user/account';
import {defaultMenu, getEskyMenu} from 'components/navbar/refMenu';
import {navbarTranslations} from 'helpers/translations';

Object.keys(locales).forEach((key) => {
  locales[key].lang = locales[key].locale.split('-')[0];
});

const getNavbarLinks = () => {
  if (isEsky) {
    return getEskyMenu();
  }
  if (isAffiliate) {
    return defaultMenu;
  }
  return menuHeader;
};

const getCMSLocales = (locales, {languages}) =>
  Object.keys(locales).reduce((acc, key) => {
    const lang = languages.find(({language_code, active}) => locales[key].lang === language_code && active);
    if (lang) {
      acc[key] = {
        ...locales[key],
        url: lang.url
      };
    }
    return acc;
  }, {});
const localizationChannel = postal.channel('localization');
const employeeChannel = postal.channel('employee');
const links = getNavbarLinks();
export const allLocales = R.isEmpty(cms) ? locales : getCMSLocales(locales, cms);

const userAccount = getAuthParams();

export default new NavbarStore({
  brand, getHost, website, links, userAccount, locales: allLocales, localeFull, brandLocale,
  multicurrency, currency: isoCurrency(), employeeChannel, localizationChannel, contactForm: contactForm(),
  getUserAccount, translations: navbarTranslations
});
