import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {testPasswordTranslations} from 'User/helpers/translations';

const {
  passwordContain, passwordLowercase, passwordUppercase, passwordNumber, passwordSymbol,
  passwordLength, passwordStrength, stateWeak, stateAverage, stateStrong
} = testPasswordTranslations;

const getState = (score) => {
  if (score < 50) {
    return {state: stateWeak, bgColor: 'bg-danger'};
  } else if (score < 100) {
    return {state: stateAverage, bgColor: 'bg-warning'};
  }
  return {state: stateStrong, bgColor: 'bg-success'};
};

const PasswordState = ({lowerCase, numbers, upperCase, symbol, length, score}) => {

  const {state, bgColor} = getState(score);

  return (
    <div className="mb-5 text-small">
      <div className="text-muted">{passwordContain}:</div>
      <div className="grid small-gutter">
        <span className="grid-item-sm-1-2">
          <i className={`i-circle bg-${lowerCase ? 'success' : 'danger'} mr-2`} />{passwordLowercase}
        </span>
        <span className="grid-item-sm-1-2">
          <i className={`i-circle bg-${numbers ? 'success' : 'danger'} mr-2`} />{passwordNumber}
        </span>
        <span className="grid-item-sm-1-2">
          <i className={`i-circle bg-${upperCase ? 'success' : 'danger'} mr-2`} />{passwordUppercase}
        </span>
        <span className="grid-item-sm-1-2">
          <i className={`i-circle bg-${symbol ? 'success' : 'danger'} mr-2`} />{passwordSymbol}
        </span>
        <span className="grid-item-sm-1-2">
          <i className={`i-circle bg-${length ? 'success' : 'danger'} mr-2`} />{passwordLength}
        </span>
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <span>{passwordStrength} - {state}</span>
        <div className="progress w-1-3">
          <div className={`progress-bar ${bgColor}`} style={{'width': `${score}%`}}/>
        </div>
      </div>
    </div>
  );
};

PasswordState.propTypes = {
  lowerCase: PropTypes.bool.isRequired,
  numbers: PropTypes.bool.isRequired,
  upperCase: PropTypes.bool.isRequired,
  symbol: PropTypes.bool.isRequired,
  length: PropTypes.bool.isRequired,
  score: PropTypes.number.isRequired
};

export default memo(PasswordState);
