import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import useAsync from 'User/helpers/hooks/useAsync';
import {loginUser} from 'providers/user/auth';
import {setUserLoggedinMessage} from 'utils/session';
import {boardingRoute} from 'User/helpers/routes';
import {isProduction} from 'helpers/stableValues';
import useScript from 'helpers/hooks/useScript';
import appleLogo from 'assets/images/social/apple.png';

const SocialAccount = ({isAccountPage, isModal, btnGoogleAccountId, btnAppleLabel}) => {
  const {isSuccess, data, execute} = useAsync();
  const googleAccountScript = useScript('https://accounts.google.com/gsi/client');

  useEffect(() => {
    const initGoggleAccounts = async () => {
      window.google.accounts.id.initialize({
        client_id: '1016037285519-ko39v4c8q18elpi9hovf3e1i0bdcgfcj.apps.googleusercontent.com',
        callback: async ({credential}) => {
          await execute(loginUser({
            accessToken: credential,
            email: null,
            password: null,
            provider: 'google',
            providerUserID: null
          }));
        }
      });
      window.google.accounts.id.renderButton(
        document.getElementById(btnGoogleAccountId),
        {theme: 'outline', size: 'large', width: '280'}
      );
      window.google.accounts.id.prompt();
    };

    if (googleAccountScript === 'ready' && window?.google?.accounts?.id) {
      initGoggleAccounts();
    }
  }, [execute, btnGoogleAccountId, googleAccountScript]);

  useEffect(() => {
    setTimeout(() => {
      window.FB?.init({
        appId : '451880447871547',
        cookie: true,
        xfbml : true,
        version : 'v21.0'
      });
    }, 1300);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.AppleID?.auth?.init({
        clientId : 'com.ferryscanner.www',
        scope : 'name email',
        redirectURI : 'https://www.ferryscanner.com/en/ferry',
        state : '',
        nonce : 'NONCE',
        usePopup : true
      });
    }, 500);
  }, []);

  useEffect(() => {
    if (isSuccess && data) {
      const {result: {uuid, session_token}} = data;
      localStorage.setItem('user', JSON.stringify({uuid, session_token}));
      Cookies.set(
        'TP24ID',
        session_token,
        {path: '/', secure: isProduction, sameSite: 'lax'}
      );

      setTimeout(() => {
        if (isAccountPage) {
          window.location = boardingRoute;
        }

        if (isModal) {
          setUserLoggedinMessage();
          location.reload();
        }
      }, 500);
    }
  }, [isSuccess, data, isAccountPage, isModal]);

  const onloginApple = async () => {
    try {
      const {authorization, user} = await window.AppleID.auth.signIn();
      execute(loginUser({
        accessToken: authorization.id_token,
        provider: 'apple',
        email: null,
        password: null,
        providerUserID: null,
        firstName: user?.name?.firstName ?? null,
        lastName: user?.name?.lastName ?? null
      }));
    } catch {
      return;
    }
  };

  const onloginFacebook = () => {
    window.FB.login((response) => {
      if (response.authResponse) {
        const {accessToken, userID} = response.authResponse;
        execute(loginUser({
          accessToken: accessToken,
          email: null,
          password: null,
          provider: 'facebook',
          providerUserID: userID
        }));
        // FB.api('/me', {fields: 'name, email'}, async ({id, email}) => {});
      }
    }, {scope: 'email'});
  };

  return (
    <div className="d-flex flex-column align-items-center">
      <div id={btnGoogleAccountId} />
      <div
        className="bg-white mt-3 border d-flex justify-content-center align-items-center cursor-pointer"
        style={{width: '280px', height: '40px'}}
        onClick={onloginApple}
      >
        <img
          src={appleLogo}
          className="mr-2"
          alt={btnAppleLabel}
          title={btnAppleLabel}
          width="18px"
          height="18"
        />
        <b>{btnAppleLabel}</b>
      </div>
      <div className="mt-3 cursor-pointer" onClick={onloginFacebook}>
        <div
          style={{pointerEvents: 'none'}}
          className="fb-login-button"
          data-width="280"
          data-size="large"
          data-button-type=""
          data-layout=""
          data-use-continue-as="true" />
      </div>
    </div>
  );
};

SocialAccount.propTypes = {
  isAccountPage: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
  btnGoogleAccountId: PropTypes.string.isRequired,
  btnAppleLabel: PropTypes.string.isRequired
};

export default SocialAccount;
