import request from 'superagent';
import {domain} from 'ferries/helpers/common';

export const fetchConfiguration = (currency) =>
  request
    .get(`${domain}/api/v1/ferries/configuration`)
    .set('Content-Type', 'application/json')
    .query([
      `currency=${currency}`,
      'params[]=currencyDetails'
    ].join('&'));
