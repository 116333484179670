import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {PropTypes as MobxPropTypes} from 'mobx-react';
import classNames from 'classnames';

const FieldErrors = ({id, className, errors}) => {
  const htmlClasses = classNames(
    className,
    {
      'error': true,
      'error-message': true
    }
  );

  if (!errors.length) {
    return null;
  }

  return (
    <div id={id} className={htmlClasses}>
      <ul>
        { errors.map((error, i) => <li key={i}>{error}</li>) }
      </ul>
    </div>
  );
};

FieldErrors.propTypes = {
  errors: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.string).isRequired,
  id: PropTypes.string,
  className: PropTypes.string
};

FieldErrors.defaultProps = {
  id: null,
  className: null
};

export default memo(FieldErrors);
