import React, {memo, useState} from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/lib/Dropdown';
import CustomToggle from 'helpers/CustomToggle';
import Avatar from 'components/atoms/Avatar';
import useAsync from 'User/helpers/hooks/useAsync';
import {logoutUser} from 'providers/user/auth';
import {showNotification} from 'helpers/Notifications';
import {dashboardRoute} from 'User/helpers/routes';
import {initialName} from 'helpers/text';

const HeaderUser = ({account, translations}) => {
  const [isOpen, setIsOpen] = useState(false);
  const {execute} = useAsync();

  const {viewDashboard, logout, logoutSuccessful} = translations;

  const handleOpen = () => setIsOpen((isOpen) => !isOpen);

  const onLogout = async () => {
    const {isSuccess} = await execute(logoutUser());
    if (isSuccess) {
      localStorage.removeItem('user');
      showNotification(logoutSuccessful);
      window.location.reload();
    }
  };

  return (
    <Dropdown
      id="header-user"
      onToggle={handleOpen}
      open={isOpen}
      componentClass="div"
    >
      <CustomToggle bsRole="toggle" className="cursor-pointer d-flex align-items-center text-white">
        <Avatar>{initialName(account.firstName, account.lastName)}</Avatar>
        {/*<div className="text-truncate d-none d-md-block" style={{maxWidth: '70px'}}>{account.firstName}</div>*/}
        <i className="icon icon-down-arrow-1 text-primary icon-sm ml-0 ml-lg-2 mr-5 mr-lg-2" />
      </CustomToggle>
      <Dropdown.Menu className="w-1-1 dropdown-menu-right">
        <li>
          <a
            href={dashboardRoute}
            target="_blank"
            rel="noreferrer"
            className="select-list-group list-group-flush px-2 d-block text-dark"
          >
            {viewDashboard}
          </a>
        </li>
        <li>
          <a onClick={onLogout} className="select-list-group list-group-flush px-2 d-block text-dark">
            {logout}
          </a>
        </li>
      </Dropdown.Menu>
    </Dropdown>
  );
};

HeaderUser.propTypes = {
  account: PropTypes.shape({
    userAccountID: PropTypes.string.isRequired,
    userID: PropTypes.string.isRequired,
    providerUserID: PropTypes.string.isRequired,
    provider: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    screenName: PropTypes.string.isRequired,
    birthDate: PropTypes.string.isRequired,
    dateCreated: PropTypes.number.isRequired,
    nationality: PropTypes.string.isRequired,
    newsletter: PropTypes.bool.isRequired
  }),
  translations: PropTypes.shape({
    viewDashboard: PropTypes.string.isRequired,
    logout: PropTypes.string.isRequired,
    logoutSuccessful: PropTypes.string.isRequired
  })
};

export default memo(HeaderUser);
