import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import {modalCustomStyles} from 'components/FormComponents/modal/common';

const ModalMobileInfo = ({show, title, style, childrenStyle, toggleModal, children}) => (
  <Modal
    isOpen={show}
    onRequestClose={toggleModal}
    contentLabel=""
    style={{...modalCustomStyles, ...style}}
    ariaHideApp={false}
  >
    <div className="modal-header border-bottom-0">
      <h4 className="modal-title text-truncate">{title}</h4>
      <button type="button" className="close" onClick={toggleModal}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div className="p-3" style={childrenStyle}>
      {children}
    </div> 
  </Modal>
);

ModalMobileInfo.defaultProps = {
  style: {},
  childrenStyle: {}
};

ModalMobileInfo.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired,
  childrenStyle: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node
  ]).isRequired
};

export default ModalMobileInfo;
