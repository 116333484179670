import React, {memo} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Label = ({id, className, htmlFor, children}) => {
  const htmlClasses = classNames(
    'control-label',
    className
  );

  return (
    <label
      id={id}
      className={htmlClasses}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  );
};

Label.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  htmlFor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

Label.defaultProps = {
  id: null,
  className: null,
  htmlFor: null,
  children: null
};

export default memo(Label);
