import {useState, useEffect} from 'react';
import {language} from 'helpers/stableValues';

const localizationsDateFns = {
  en: 'en-GB', de: 'de', el: 'el', es: 'es', fr: 'fr', it: 'it',
  nl: 'nl', ru: 'ru', tr: 'tr', pt: 'pt', sv: 'sv', pl: 'pl',
  da: 'da', fi: 'fi', no: 'nb', et: 'et', zh: 'zh-CN', ko: 'ko',
  ja: 'ja', sq: 'sq', hr: 'hr'
};

const useLocalizationLoadFile = () => {
  const [selected, setSelected] = useState(null);
  const locale = language;

  useEffect(() => {
    const importLocaleFile = async () => {
      const localeToSet = await import(
        `date-fns/locale/${localizationsDateFns[locale] ?? localizationsDateFns.en}/index.js`
      );
      setSelected(localeToSet);
    };

    if (!selected) {
      importLocaleFile();
    }
    
  }, [selected, locale]);

  return selected;
};

export default useLocalizationLoadFile;