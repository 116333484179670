import _tp24 from 'tp24';
import * as R from 'ramda/src/index';
import {replaceCmsUrl} from 'cms/helpers/common';

const bookingFlowUrls = R.pathOr({}, ['data', 'booking_flow_url'], _tp24);

const createLanguageUrl = ({selLanguage, preferredLang}) => {
  const {url, language, locale} = selLanguage;
  const allBookingFlowUrls = Object.entries(bookingFlowUrls).reduce((acc, [, value]) => {
    acc.push(value.home);
    return acc;
  }, []);

  const preferredPath = location.pathname.substring(1).split('/')[1];

  if (url) {
    return replaceCmsUrl(url);
  } else if (allBookingFlowUrls.includes(preferredPath)) {
    const path = bookingFlowUrls?.[locale]?.home ?? '';
    return (location.href).replace(`/${preferredLang}/${preferredPath}`, `/${language}/${path}`);
  }

  return (location.href).replace(`/${preferredLang}/`, `/${language}/`);
};

export {
  createLanguageUrl
};
