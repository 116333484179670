import React from 'react';
import PropTypes from 'prop-types';
import {observer} from 'mobx-react';
import classNames from 'classnames';
import {useMediaQuery} from 'react-responsive';
import Navigation from './Navigation';
import LoggedInEmployee from './LoggedInEmployee';
import HeaderUser from './HeaderUser';
import ButtonUser from './ButtonUser';
import AccountModal from 'User/AccountModal';
import Localization from 'components/authentication/Localization';
import Navbar from 'react-bootstrap/lib/Navbar';
import Avatar from 'components/atoms/Avatar';
import Button from 'helpers/Button';
import LazyImage from 'helpers/LazyImage';
import {brandLogo, openBeacon} from 'helpers/commonHelper';
import {DESKTOP_WIDTH} from 'helpers/stableValues';
import {
  language,
  controller,
  isFerryscanner,
  isAirtickets,
  isAffiliate
} from 'helpers/stableValues';
import {replaceAssetsImgUrl} from 'cms/helpers/common';
import {isEsky, capitalize} from 'ferries/helpers/common';
import {manageBookingRoute} from 'User/helpers/routes';
import {initialName} from 'helpers/text';
import eskyPartnershipLogo from 'assets/images/partnership/esky.png';
import {isNative} from '../../ferries/helpers/common';

const getLogo = (brand) => {
  if (isAffiliate) {
    return replaceAssetsImgUrl(isAffiliate.logo.url);
  } else if (isEsky) {
    return eskyPartnershipLogo;
  }

  return brandLogo(brand, 'logo');
};

const isSeatingOrPaymentPage = ['ferry_seating', 'ferry_payment'].includes(controller);
const isFerryBookingFlowPages = ['ferry_results', 'ferry_seating', 'ferry_payment'].includes(controller);
const isManageBookingPages = location.pathname.includes(manageBookingRoute);

const NavigationBar = ({navbar, navbarClass}) => {
  const {
    isPreferredCurrencyEnabled,
    locales,
    brandLocale,
    preferredLocale,
    defaultLocale,
    currencies,
    preferredCurrency,
    defaultCurrency,
    host,
    brand,
    contactForm,
    employee,
    account,
    translations
  } = navbar;

  const isMobile = useMediaQuery({query: `(max-width: ${DESKTOP_WIDTH}px)`});
  const capitalizeBrand = capitalize(brand);
  const {headerContact, blog, login, register, firstnameAccount, letsChat, myBooking} = translations;

  const listClasses = classNames('navbar-nav ml-auto', {
    airtickets: isAirtickets
  });

  const navbarBrandClasses = classNames('navbar-brand', {
    'more-space': isEsky
  });

  const isNativeLayout = isNative();
  if (isNativeLayout) {
    return <></>;
  }

  return (
    <>
      {employee && <LoggedInEmployee employee={employee} />}
      <Navbar className={navbarClass}
        style={{borderBottom: isFerryscanner ? '1px solid #EDEDED' : '0'}}>
        <a className={navbarBrandClasses} href={host}>
          <LazyImage src={getLogo(brand)} width="160" height="27" title={capitalizeBrand} alt={capitalizeBrand} />
        </a>
        <div className="d-flex ml-auto order-lg-1">
          {account?.userAccountID && <HeaderUser account={account} translations={translations} />}
          <Navbar.Toggle />
        </div>
        <Navbar.Collapse>
          {account?.userAccountID && (
            <div className="d-flex align-items-center d-block d-lg-none py-3 border-bottom">
              <Avatar circle={48}>
                <span className="display-3 font-normal">{initialName(account.firstName, account.lastName)}</span>
              </Avatar>
              <div className="text-primary text-truncate">
                <div className="text-lead">
                  {firstnameAccount.replace('{firstname}', account.firstName)}
                </div>
                {account.email}
              </div>
            </div>
          )}
          <Navigation
            links={navbar.links}
            userAccountID={account?.userAccountID}
          />
          {!isAirtickets && !account?.userAccountID &&
            <ul className="navbar-nav ml-auto d-lg-none d-block border-top border-bottom">
              {isFerryBookingFlowPages ?
                <AccountModal
                  isMobile={isMobile}
                >
                  {(toggleModal) => (
                    <li className="nav-item pb-2" onClick={toggleModal}>
                      <a className="navbar-link d-flex">
                        <Avatar>
                          <i className="icon icon-profile icon-sm" />
                        </Avatar>
                        {login} / {register}
                      </a>
                    </li>
                  )}
                </AccountModal>
                :
                <li className="nav-item pb-2">
                  <a
                    className="navbar-link d-flex"
                    href={`/${language}/user`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Avatar>
                      <i className="icon icon-profile icon-sm" />
                    </Avatar>
                    {login} / {register}
                  </a>
                </li>}
            </ul>
          }
          <ul className={listClasses}>
            {isFerryscanner && !isManageBookingPages &&
            <li className="nav-item">
              <a
                className="navbar-link d-flex align-items-center"
                href={manageBookingRoute}
                rel="noreferrer"
              >
                <i className='icon icon-event-confirm mr-2' />{myBooking}
              </a>
            </li>}
            {isFerryscanner && !isSeatingOrPaymentPage
              && !isAffiliate && ['el', 'en', 'fr', 'es', 'it', 'de', 'sv'].includes(language) && (
              <li className="nav-item">
                <a
                  className="navbar-link"
                  href={`/${language}/blog`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {blog}
                </a>
              </li>
            )}
            {!isSeatingOrPaymentPage && !isAirtickets && (
              <li className="nav-item">
                <Localization
                  isPreferredCurrencyEnabled={isPreferredCurrencyEnabled}
                  locales={locales}
                  brandLocale={brandLocale}
                  defaultLocale={defaultLocale}
                  defaultCurrency={defaultCurrency}
                  currencies={currencies}
                  preferredCurrency={preferredCurrency}
                  preferredLocale={preferredLocale}
                  translations={translations}
                />
              </li>
            )}
            {((isMobile && !isAirtickets) || (isAirtickets && !contactForm)) &&
                <li className="nav-item">
                  <a className="navbar-link" onClick={openBeacon}>{letsChat}</a>
                </li>
            }
            {isAirtickets && contactForm && (
              <li className="nav-item">
                <a
                  className='navbar-link d-flex align-items-center'
                  href={contactForm}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icon icon-comment mr-2" />
                  <span>{headerContact}</span>
                </a>
              </li>
            )}
            {!isAirtickets && !account?.userAccountID && !isMobile && <>
              {isFerryBookingFlowPages ?
                <AccountModal
                  isMobile={isMobile}
                >
                  {(toggleModal) => (
                    <Button status="primary" size="sm" rel="noreferrer" onClick={toggleModal}>
                      {login}/{register}
                    </Button>
                  )}
                </AccountModal>
                :
                <ButtonUser isMobile={isMobile} language={language} translations={translations} />}
            </>}
          </ul>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

NavigationBar.propTypes = {
  navbar: PropTypes.shape({
    locales: PropTypes.object.isRequired,
    currencies: PropTypes.array.isRequired,
    brandLocale: PropTypes.string.isRequired,
    preferredLocale: PropTypes.string.isRequired,
    isPreferredCurrencyEnabled: PropTypes.bool.isRequired,
    defaultLocale: PropTypes.string.isRequired,
    defaultCurrency: PropTypes.string.isRequired,
    preferredCurrency: PropTypes.string.isRequired,
    account: PropTypes.object,
    host: PropTypes.string.isRequired,
    brand: PropTypes.string.isRequired,
    contactForm: PropTypes.string.isRequired,
    employee: PropTypes.object,
    links: PropTypes.array.isRequired,
    translations: PropTypes.shape({
      headerContact: PropTypes.string.isRequired,
      customerService: PropTypes.string.isRequired,
      blog: PropTypes.string.isRequired,
      login: PropTypes.string.isRequired,
      register: PropTypes.string.isRequired,
      firstnameAccount: PropTypes.string.isRequired,
      letsChat: PropTypes.string.isRequired,
      myBooking: PropTypes.string.isRequired
    })
  }),
  navbarClass: PropTypes.string.isRequired
};

NavigationBar.defaultProps = {
  navbarClass: ''
};

export default observer(NavigationBar);
