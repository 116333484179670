import React, {useState} from 'react';
import PropTypes from 'prop-types';
import RegionalSettingModal from 'components/authentication/RegionalSettingModal';
import currencySymbol from 'lib/helpers/currency-symbol-map';

const Localization = ({
  isPreferredCurrencyEnabled,
  locales,
  brandLocale,
  defaultLocale,
  defaultCurrency,
  currencies,
  preferredCurrency,
  preferredLocale,
  translations
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen((isModalOpen) => !isModalOpen);
  };

  return (
    <>
      <span className="navbar-link cursor-pointer" onClick={toggleModal}>
        <span>
          <span className="mr-2">
            {locales[defaultLocale].language}
          </span>
          <i className="icon icon-down-arrow icon-10" />
        </span>
        {
          isPreferredCurrencyEnabled &&
            <span className="ml-3">
              <span className="mr-2">
                {defaultCurrency}&nbsp;•&nbsp;
                {currencySymbol[defaultCurrency]}
              </span>
              <i className="icon icon-down-arrow icon-10" />
            </span>
        }
      </span>
      {
        isModalOpen &&
          <RegionalSettingModal
            locales={locales}
            brandLocale={brandLocale}
            preferredLocale={preferredLocale}
            isPreferredCurrencyEnabled={isPreferredCurrencyEnabled}
            currencies={currencies}
            preferredCurrency={preferredCurrency}
            toggleModal={toggleModal}
            translations={translations}
          />
      }
    </>
  );
};

Localization.propTypes = {
  locales: PropTypes.object.isRequired,
  currencies: PropTypes.array.isRequired,
  brandLocale: PropTypes.string.isRequired,
  preferredLocale: PropTypes.string.isRequired,
  isPreferredCurrencyEnabled: PropTypes.bool.isRequired,
  defaultLocale: PropTypes.string.isRequired,
  defaultCurrency: PropTypes.string.isRequired,
  preferredCurrency: PropTypes.string.isRequired,
  translations: PropTypes.object.isRequired
};

export default Localization;
