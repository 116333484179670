import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import Popover from 'react-bootstrap/lib/Popover';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import Badge from 'react-bootstrap/lib/Badge';
import useWindowEvent from 'helpers/hooks/useWindowEvent';
import Button from 'helpers/Button';
import faceImage from 'User/helpers/images/face.svg';

const displayBtn = (language, login, register) => (
  <Button status="primary" size="sm" href={`/${language}/user`} target="_blank" rel="noreferrer">
    {login}/{register}
  </Button>
);

const ButtonUser = ({isMobile, language, translations}) => {
  const [show, setShow] = useState(!localStorage.getItem('userpromo'));
  const [overlayKey, setOverlayKey] = useState(new Date().getTime());
 
  const onResize = useCallback(() => {
    if (!isMobile) {
      setOverlayKey(new Date().getTime());
    }
  }, [isMobile]);

  useWindowEvent('resize', onResize);

  const {
    new: newBadge,
    userBtnTooltipTitle,
    userBtnTooltipBody,
    login,
    register
  } = translations;

  const onClose = () => {
    setShow((show) => !show);
    localStorage.setItem('userpromo', true);
  };

  const popover = (
    <Popover
      id="button-user"
      style={{width: '300px', maxWidth: '300px', zIndex: 80}}
      positionLeft={'500px'}
      positionTop={'500px'}
    >
      <div className="p2">
        <div className="d-flex align-items-center justify-content-between mb-1">
          <div>
            <img src={faceImage} width="32" height="32" alt={userBtnTooltipTitle} title={userBtnTooltipTitle}/>
            <b className="ml-2 mr-3">{userBtnTooltipTitle}</b>
            <Badge
              style={{fontSize: '10px'}}
              className="bg-primary rounded-pill text-white text-uppercase"
            >
              {newBadge}
            </Badge>
          </div>
          <i className="icon icon-e-remove" onClick={onClose} />
        </div>
        <div className="text-small">{userBtnTooltipBody}</div>
      </div>
    </Popover>
  );

  return (
    <li className="nav-item">
      {show ? (
        <OverlayTrigger
          key={overlayKey}
          trigger="click"
          placement="bottom"
          overlay={popover}
          defaultOverlayShown
        >
          {displayBtn(language, login, register)}
        </OverlayTrigger>
      ) : (
        displayBtn(language, login, register)
      )}
    </li>
  );
};

ButtonUser.propTypes = {
  isMobile:PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  translations: PropTypes.shape({
    login: PropTypes.string.isRequired,
    register: PropTypes.string.isRequired,
    new: PropTypes.string.isRequired,
    userBtnTooltipTitle: PropTypes.string.isRequired,
    userBtnTooltipBody: PropTypes.string.isRequired
  })
};

export default ButtonUser;
