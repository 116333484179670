import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {PropTypes as MobxPropTypes} from 'mobx-react';
import classnames from 'classnames';
import {FieldContainer, Label, FieldErrors} from 'components/FormComponents/components';

class OptionsToggle extends PureComponent {
  handleClick = (e) => {
    e.preventDefault();
    this.props.onClick && this.props.onClick(e);
  };

  render() {
    const {containerProps, className, faIcon, labelProps, label,
      errorsContainerProps, errors, htmlFor, isDisabled, value} = this.props;
    const faIconClasses = classnames('icon', faIcon);
    const htmlClasses = classnames('floating-label-select', className);
    const hasError = Boolean(errors.length);

    return (
      <div onClick={this.handleClick} className="input-icon input-icon-flip">
        <FieldContainer
          {...containerProps}
          value={value}
          className={htmlClasses}
          hasError={hasError}
        >
          <input
            className="form-control form-control-md border-radius-lg"
            value={value || label}
            disabled={isDisabled}
            readOnly
          />
          {label && <Label {...labelProps} htmlFor={htmlFor}>{label}</Label>}
          {hasError && <FieldErrors {...errorsContainerProps} errors={errors} />}
        </FieldContainer>
        <span className={faIconClasses} aria-hidden="true"></span>
      </div>
    );
  }
}

OptionsToggle.propTypes = {
  containerProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  className: PropTypes.string,
  faIcon: PropTypes.string.isRequired,
  labelProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  label: PropTypes.string.isRequired,
  errorsContainerProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string
  }),
  errors: MobxPropTypes.arrayOrObservableArrayOf(PropTypes.string).isRequired,
  htmlFor: PropTypes.string,
  isDisabled: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

OptionsToggle.defaultProps = {
  containerProps: {},
  labelProps: {},
  label: '',
  errorsContainerProps: {},
  errors: [],
  htmlFor: null,
  isDisabled: false,
  value: null
};

export default OptionsToggle;
