import React from 'react';
import PropTypes from 'prop-types';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import Login from 'User/Account/Login';
import Register from 'User/Account/Register';
import {userAccountTranslations} from 'User/helpers/translations';

const TabsAccount = ({isAccountPage, isModal, tabIndexSelected, email, onChangeTab}) => (
  <Tabs
    selectedIndex={tabIndexSelected}
    onSelect={onChangeTab}
    selectedTabClassName='active'
    forceRenderTabPanel
    selectedTabPanelClassName="fade in active"
  >
    <TabList className="nav nav-decoration mb-4">
      <Tab className="nav-item">
        {userAccountTranslations.login}
      </Tab>
      <Tab className="nav-item">
        {userAccountTranslations.register}
      </Tab>
    </TabList>
    <div className="tab-content">
      <TabPanel className="tab-pane">
        <Login
          isAccountPage={isAccountPage}
          isModal={isModal}
          email={email}
          onChangeTab={onChangeTab}
        />
      </TabPanel>
      <TabPanel className="tab-pane">
        <Register
          isAccountPage={isAccountPage}
          isModal={isModal}
          email={email}
          onChangeTab={onChangeTab}
        />
      </TabPanel>
    </div>
  </Tabs>
);

TabsAccount.defaultProps = {
  isAccountPage: false,
  isModal: false
};

TabsAccount.propTypes = {
  isAccountPage: PropTypes.bool.isRequired,
  isModal: PropTypes.bool.isRequired,
  tabIndexSelected: PropTypes.number.isRequired,
  email: PropTypes.string,
  onChangeTab: PropTypes.func.isRequired
};

export default TabsAccount;
