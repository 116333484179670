import React, {Component} from 'react';
import PropTypes from 'prop-types';

class CustomToggle extends Component {
  handleClick = (e) => {
    e.preventDefault();
    this.props.onClick(e);
  };

  render() {
    const {className, children} = this.props;

    return (
      <div onClick={this.handleClick} className={className}>
        {children}
      </div>
    );
  }
}

CustomToggle.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node
  ]).isRequired
};

export default CustomToggle;
