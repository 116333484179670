import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/lib/Modal';

const ModalInfo = ({show, title, toggleModal, attrs, children}) => (
  <Modal
    show={show}
    onHide={toggleModal}
    {...attrs}
  >
    <div className="modal-header border-bottom-0">
      <h4 className="modal-title">{title}</h4>
      <button type="button" className="close" onClick={toggleModal}>
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <Modal.Body>
      {children}
    </Modal.Body>
  </Modal>
);

ModalInfo.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  attrs: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node
  ]).isRequired
};

ModalInfo.defaultProps = {
  attrs: {}
};

export default ModalInfo;
